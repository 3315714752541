import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Box, Typography, Grid, Paper, Container } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import PhoneIcon from "@mui/icons-material/Phone";

import jasurbek from "../assets/jasurbek.jpg";
import sultonbek from "../assets/sultonbek.jpg";
import imgplaceholder from "../assets/imgplaceholder.jpg";
import shaxobiddin from "../assets/shaxobiddin.jpg";

const ContactBox = () => {
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container id="contact" maxWidth="lg" className="contact_container">
      <Box className="page_title">
        <Box className="contact_title">
          <span>{t("contact.title")}</span>
        </Box>
      </Box>

      <Typography variant="h4" align="center" style={{ marginBottom: "20px" }}>
        {t("contact.inDe")}
      </Typography>

      <Paper
        elevation={3}
        sx={{ p: 2, mx: "auto", my: 2, borderRadius: "20px" }}
      >
        <Grid container spacing={2}>
          {/* First Column */}
          <Grid item xs={12} md={4}>
            <Box display="flex" alignItems="center" mb={2}>
              <AccountBoxIcon sx={{ mr: 1, color: "#3c5132" }} />
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Jasur Nematjonov
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <LocationCityIcon sx={{ mr: 1, color: "#3c5132" }} />
              <Typography variant="h6">{t("contact.city1")}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <PhoneIcon sx={{ mr: 1, color: "#3c5132" }} />
              <a
                href="tel:+4917643656708"
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography variant="h6">+49 (0) 176 43 656 708</Typography>
              </a>
            </Box>
            <Box display="flex" alignItems="center">
              <PhoneIcon sx={{ mr: 1, color: "#3c5132" }} />
              <a
                href="tel:+4972217714767"
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography variant="h6">+49 (0) 722 17 714 767</Typography>
              </a>
            </Box>
            <Box display="flex" alignItems="center">
              <EmailIcon sx={{ mr: 1, color: "#3c5132" }} />
              <a
                href="mailto:info@jas-vermittlung.de"
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography variant="h6">info@jas-vermittlung.de</Typography>
              </a>
            </Box>
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} md={4}>
            <Typography
              variant="h5"
              component="div"
              mb={2}
              sx={{ fontWeight: "bold" }}
            >
              {t("contact.address")}
            </Typography>
            <Typography variant="h6">{t("contact.address11")}</Typography>
            <Typography variant="h6">{t("contact.address12")}</Typography>
            <Typography variant="h6">{t("contact.address13")}</Typography>
          </Grid>

          {/* Third Column */}
          <Grid
            item
            xs={12}
            md={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={jasurbek}
              alt="Jasurbek"
              style={{
                maxWidth: "200px",
                height: "auto",
                borderRadius: "20px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              }}
            />
          </Grid>
        </Grid>
      </Paper>

      <Typography variant="h4" align="center" style={{ margin: "30px 0" }}>
        {t("contact.inUz")}
      </Typography>

      <Paper
        elevation={3}
        sx={{ p: 2, mx: "auto", my: 2, borderRadius: "20px" }}
      >
        <Grid container spacing={2}>
          {/* First Column */}
          <Grid item xs={12} md={4}>
            <Box display="flex" alignItems="center" mb={2}>
              <AccountBoxIcon sx={{ mr: 1, color: "#3c5132" }} />
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Sultonbek Qosimov
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <LocationCityIcon sx={{ mr: 1, color: "#3c5132" }} />
              <Typography variant="h6">{t("contact.city2")}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <PhoneIcon sx={{ mr: 1, color: "#3c5132" }} />
              <a
                href="tel:+998992004815"
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography variant="h6">+998 99 2004815</Typography>
              </a>
            </Box>

            <Box display="flex" alignItems="center">
              <EmailIcon sx={{ mr: 1, color: "#3c5132" }} />
              <a
                href="mailto:sultonbek_germany@mail.ru"
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography variant="h6">sultonbek_germany@mail.ru</Typography>
              </a>
            </Box>
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} md={4}>
            <Typography
              variant="h5"
              component="div"
              mb={2}
              sx={{ fontWeight: "bold" }}
            >
              {t("contact.address")}
            </Typography>
            <Typography variant="h6">{t("contact.address31")}</Typography>
            <Typography variant="h6">{t("contact.address32")}</Typography>
            {/* <Typography variant="h6">{t("contact.address13")}</Typography> */}
          </Grid>

          {/* Third Column */}
          <Grid
            item
            xs={12}
            md={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={sultonbek}
              alt="Sultonbek"
              style={{
                maxWidth: "200px",
                height: "auto",
                borderRadius: "20px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              }}
            />
          </Grid>
        </Grid>
      </Paper>

      <Paper
        elevation={3}
        sx={{ p: 2, mx: "auto", my: 2, borderRadius: "20px" }}
      >
        <Grid container spacing={2}>
          {/* First Column */}
          <Grid item xs={12} md={4}>
            <Box display="flex" alignItems="center" mb={2}>
              <AccountBoxIcon sx={{ mr: 1, color: "#3c5132" }} />
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                Shahobiddin Abdufattoxov
              </Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <LocationCityIcon sx={{ mr: 1, color: "#3c5132" }} />
              <Typography variant="h6">{t("contact.city3")}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <PhoneIcon sx={{ mr: 1, color: "#3c5132" }} />
              <a
                href="tel:+998943327401"
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography variant="h6">+998 94 3327401</Typography>
              </a>
            </Box>

            <Box display="flex" alignItems="center">
              <EmailIcon sx={{ mr: 1, color: "#3c5132" }} />
              <a
                href="mailto:shahobdn1@gmail.com"
                style={{ color: "black", textDecoration: "none" }}
              >
                <Typography variant="h6">shahobdn1@gmail.com</Typography>
              </a>
            </Box>
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} md={4}>
            <Typography
              variant="h5"
              component="div"
              mb={2}
              sx={{ fontWeight: "bold" }}
            >
              {t("contact.address")}
            </Typography>
            <Typography variant="h6">{t("contact.address21")}</Typography>
            <Typography variant="h6">{t("contact.address22")}</Typography>
            <Typography variant="h6">{t("contact.address23")}</Typography>
          </Grid>

          {/* Third Column */}
          <Grid
            item
            xs={12}
            md={4}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              src={shaxobiddin}
              alt="Image placeholder"
              style={{
                maxWidth: "200px",
                height: "auto",
                borderRadius: "20px",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default ContactBox;
